<template>
  <div class="customer-care-page">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="'Danh sách phiếu CSKH'">
      <template v-slot:toolbar>
        <div class="row">
          <router-link
            to="/customer-care/add-customer-care"
            v-if="checkPermission('CUSTOMER_CARE_INSERT')"
          >
            <b-button v-bind:style="btnCreate" variant="primary" size="sm">
              <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm mới
            </b-button>
          </router-link>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-5">
          <b-col md="3">
            <b-form-input
              v-on:keyup.enter="onFilter()"
              v-model="billId"
              type="text"
              placeholder="Tìm theo hóa đơn"
              size="sm"
            ></b-form-input>
          </b-col>
          <b-col md="3">
            <b-form-input
              v-on:keyup.enter="onFilter()"
              v-model="searchCustomer"
              type="text"
              placeholder="Tìm theo khách hàng"
              size="sm"
            ></b-form-input>
          </b-col>
          <b-col md="3">
            <div class="col">
              <div class="row">
                <b-lable class="mr-2 d-flex align-items-center"
                  >Hành động:</b-lable
                >
                <b-form-select
                  size="sm"
                  required
                  v-model="selectedCareType"
                  :options="listTypeSearch"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                  class="col-md-7 select-style"
                ></b-form-select>
              </div>
            </div>
          </b-col>
          <b-col md="3">
            <div class="date-style">
              <b-input-group>
                <date-picker
                  placeholder="Từ"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="fromDate"
                ></date-picker>
              </b-input-group>
              <span class="ml-1 mr-1"></span>
              <b-input-group>
                <date-picker
                  placeholder="Đến"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="toDate"
                ></date-picker>
              </b-input-group>
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="careForms"
          :fields="fields"
          :busy="onLoading"
          bordered
          hover
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>

          <template v-slot:cell(count)="row">
            <div style="text-align: center">
              <span v-text="row.item.count" class="text-right"></span>
            </div>
          </template>
          <template v-slot:cell(customerName)="row">
            <p
              style="
                white-space: normal;
                color: lightslategray;
                font-size: 13px;
                font-weight: 500;
              "
            >
              {{ row.item.customerName }}
            </p>
            <p style="color: blue">
              {{ row.item.customerPhone }}
            </p>
          </template>
          <template v-slot:cell(createdBy)="row">
            <p style="white-space: normal; font-size: 13px; font-weight: 500">
              {{ row.item.createdBy }}
            </p>
            <p>{{ row.item.createdAt }}</p>
          </template>
          <template v-slot:cell(billId)="row">
            <div style="color: #2e7d32">
              <span v-text="row.item.billId" class="text-center"></span>
            </div>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="!checkViewOnly()"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="editItem(row.item)" v-else>
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon-eye"></i>
                    &nbsp; Chi tiết
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item)"
                  v-if="checkPermission('CUSTOMER_CARE_DELETE')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ convertPrice(totalItems) }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="custom-pagination"
              v-show="totalPages >= 2"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchData"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
// import { VueAutosuggest } from 'vue-autosuggest';
import { VclTable } from 'vue-content-loading';
import Swal from 'sweetalert2';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import localData from '../../../utils/saveDataToLocal';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import timeUtils from '@/utils/date';
import moment from 'moment';
import { convertPrice } from '@/utils/common';

export default {
  mixins: [validationMixin],
  data() {
    return {
      onLoading: false,
      btnCreate: {
        fontWeight: '600!important',
      },
      totalPages: 0,
      count: 1,
      totalItems: 0,
      page: 1,
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '6%',
          },
        },
        {
          key: 'customerName',
          label: 'Khách hàng',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'customerCareTypeName',
          label: 'Hành động',
          thStyle: { fontWeight: 600, color: '#181c32', width: '12%' },
        },
        {
          key: 'point',
          label: 'Điểm',
          thStyle: { fontWeight: 600, color: '#181c32', width: '8%' },
          tdClass: 'text-right font-weight-bold',
        },
        {
          key: 'billId',
          label: 'Hóa đơn',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'description',
          label: 'Mô tả',
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
        },
        {
          key: 'createdBy',
          label: 'Người tạo',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        { key: 'actions', label: '' },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      limit: 10,
      billId: '',
      careForms: [],
      selectedCareType: null,
      searchName: '',
      listTypeSearch: [
        {
          id: null,
          name: 'Tất cả',
        },
        {
          id: 1,
          name: 'Tặng điểm',
        },
        {
          id: 2,
          name: 'Trừ điểm',
        },
      ],
      fromDate: '',
      toDate: '',
      dpConfigs: timeUtils.DP_CONFIG,
      searchCustomer: '',
    };
  },
  validations: {
    name: {
      required,
    },
  },
  methods: {
    convertPrice,
    fetchData: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      const params = {
        page: this.page,
        limit: 10,
        billId: this.billId,
        customerCareType: this.selectedCareType,
        searchCustomer: this.searchCustomer,
        fromDate: this.fromDate
          ? moment(this.fromDate, 'DD/MM/YYYY').format('yyyy-MM-DD 00:00:00')
          : null,
        toDate: this.toDate
          ? moment(this.toDate, 'DD/MM/YYYY HH:mm').format(
              'yyyy-MM-DD 23:59:59'
            )
          : null,
      };
      ApiService.query('customerCare', { params })
        .then((response) => {
          const dataResponse = response.data.data.data;
          console.log(dataResponse);
          this.totalPages = response.data.data.totalPage;
          this.totalItems = response.data.data.totalRow;
          this.careForms = dataResponse.map((item, index) => {
            return {
              count:
                this.page >= 2 ? index + 1 + this.page * 10 - 10 : index + 1,
              id: item.id,
              customerName: item.customerName,
              customerCareType: item.customerCareType,
              customerCareTypeName: item.customerCareTypeName,
              billId: item.billId,
              point: item.point,
              description: item.description,
              customerPhone: item.customerPhone,
              createdBy: item.createdBy,
              createdAt: item.createdAt,
            };
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = true;
        });
    },
    editItem: function (item) {
      this.$router.push({
        name: 'update-customer-care',
        query: { id: item.id },
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa phiếu CSKH!',
        text: 'Bạn có chắc muốn xóa phiếu CSKH này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    deleteItem: async function (item) {
      ApiService.delete(`customerCare/${item.id}`).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.fetchData();
        } else {
          this.makeToastFaile(data.message);
        }
      });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-customer-care',
      });
      this.fetchData();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    checkViewOnly: function () {
      let count = 0;
      if (localData.checkPermission('CUSTOMER_CARE_VIEW')) {
        count++;
      }
      if (localData.checkPermission('CUSTOMER_CARE_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Danh sách phiếu CSKH' }]);
  },
  created() {
    let requireCode = this.$route.query.code;
    if (requireCode !== undefined) {
      this.searchId = requireCode;
    }
    this.fetchData();
  },
  components: {
    KTCodePreview,
    VclTable,
    datePicker,
  },
};
</script>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

select {
  height: 2.5em;
}

.example {
  border: 1px solid #ddd;
  margin-bottom: 2em;
}

code,
pre {
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #bbb;
  display: block;
  background: #ddd;
  border-radius: 3px;
}

.settings {
  margin: 2em 0;
  border-top: 1px solid #bbb;
  background: #eee;
}

/* h5 {
  font-size: 100%;
  padding: 0;
} */

.form-group {
  margin-bottom: 1em;
}

.icon:hover {
  background-color: #90c6fc;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}
.productCode:hover {
  text-decoration: underline;
}
</style>
<style lang="scss">
.date-style {
  display: flex;
  align-items: center;
}

.select-style {
  border-radius: 0.28rem;
  box-shadow: none;
  border: 1px solid #ced4da;
}
</style>
